$(function(){
    ///////////////////////////
    // add custom scripts below
    //////////////////////////

    //testing data autopopulate
    $('.testing-data span, .form-testing-data span').click(function(){
        var fnameField = $('#inf_field_FirstName');
        var lnameField = $('#inf_field_LastName');
        var phoneField = $('#inf_field_Phone1');
        var emailField = $('#inf_field_Email');
        var addy1Field = $('#inf_field_Address2Street1');
        var addy2Field = $('#inf_field_Address2Street2');
        var cityField = $('#inf_field_City2');
        var stateField = $('#inf_field_State2');
        var zipField = $('#inf_field_PostalCode2');

        var guestFnameField = $('.inf_custom_GuestFirstName');
        var guestLnameField = $('.inf_custom_GuestLastName');
        var guestEmailField = $('.inf_custom_Guest1Email');

        var fname = 'Jane';
        var lname = 'Doe';
        var phone = '555-555-5555';
        var email = 'janedoe@email.com';
        var addy1 = '1244 main ave';
        var addy2 = 'apt 3443';
        var city = 'denver';
        var state = 'colorado';
        var zip = '71102';
        var guestFname = 'Friend';
        var guestLname = 'Doe';
        var guestEmail = 'friendsemail@email.com';

        fnameField.val(fname);
        lnameField.val(lname);
        phoneField.val(phone);
        emailField.val(email);
        addy1Field.val(addy1);
        addy2Field.val(addy2);
        cityField.val(city);
        stateField.val(state);
        zipField.val(zip);

        guestFnameField.val(guestFname);
        guestLnameField.val(guestLname);
        guestEmailField.val(guestEmail);
        

    });

    $('.test-event-data-link').click(function(){
        console.log('test')
        $form = $('.add-event-row.default-row');
        $form.find('[name="event-location"]').val('San Antonio');
        $form.find('[name="event-venue"]').val('Holiday Inn');
        $form.find('[name="event-address"]').val('123 main st San Antonio, Tx 78124');
        $form.find('[name="event-time"]').val('10:00 AM');
        
    })
    if($('.date-picker-add').length){
        var pickerAdd = datepicker('.date-picker-add',{
            onSelect: function(instance) {
                var dateSelected = instance.dateSelected.toDateString();
                var dateArr = dateSelected.split(' ');
                var dateString = dateArr[0]+', '+instance.currentMonthName+' '+dateArr[2]+' '+dateArr[3];
                $('.date-picker-add').val(dateString);
            }
        });
        var pickerUpdate = datepicker('.date-picker-update',{
            position: 'tr',
            onSelect: function(instance) {
                var dateSelected = instance.dateSelected.toDateString();
                var dateArr = dateSelected.split(' ');
                var dateString = dateArr[0]+', '+instance.currentMonthName+' '+dateArr[2]+' '+dateArr[3];
                $('.date-picker-update').val(dateString);
            },
        });
    }
    
    $('.filter').click(function(){
        var $this = $(this);
        $this.toggleClass('active');

        var allFilter = $('.filter[data-filter="all"]')

        //if All filter is activated, reset others
        if( ($this.data('filter') === 'all') && ($this.hasClass('active') === true) ){
            $('.region-item').show();
            $('.filter').removeClass('active');
            allFilter.addClass('active');
            return;
        }

        if(allFilter.hasClass('active')){
            allFilter.removeClass('active');
        } 

        var activeFiltersArr = [];
        $('.filter.active').each(function(i,v){
            activeFiltersArr.push($(this).text())
        })

        $('.filter-list').attr('data-active-filters', activeFiltersArr);

        // if( activeFiltersArr.includes('All')){
        //     $('.region-item').show();
        //     $('.filter').removeClass('active');
        //     allFilter.addClass('active');
        //     return;
        // }

        $('.region-item').hide();
        $.each(activeFiltersArr,function(i,v){
            $('.region-item[data-status="'+v.toLowerCase()+'"]').show();
        })
    })

    $('.add-event').click(function(){
        var $form = $(this).parent().parent();
        var eventLocation = $form.find('[name="event-location"]').val();
        var eventVenue = $form.find('[name="event-venue"]').val();
        var eventAddress = $form.find('[name="event-address"]').val();
        var eventDate = $form.find('[name="event-date"]').val();
        var eventTime = $form.find('[name="event-time"]').val();
        var id = $(this).data('id');

        //extract date
        var eventArr = eventDate.replace(',','').split(' ');
        var day = eventArr[2];
        console.log(day);
        var zeroDays = ['01' , '02' , '03' , '04' , '05' , '06' , '07' , '08' , '09']
        if(zeroDays.includes(day)){
            console.log('test');
            //day = day.replace('0','');
        }
        console.log(day)
        $.ajax({
            url: '/add-event?id='+id,
            type: 'POST',
            data: {
                location: eventLocation,
                venue: eventVenue,
                address: eventAddress,
                date: {
                    month: eventArr[1],
                    weekday: eventArr[0],
                    day: day,
                    year: eventArr[3]
                },
                time: eventTime,
            }
        }).done(function(response){
            console.log(response)

            if(response.error) {

                Swal.fire({
                    title: 'Error',
                    html: response.error,
                    type: 'error',
                })

                return

            }

            $form.find('input').val('');
            $('.added-events').empty();
            $.each(response,function(i,v){
                var row =  '<tr id="'+v._id+'"><td class="event-location">'+v.location+'</td><td class="event-venue">'+v.venue+'</td><td class="event-address">'+v.address+'</td><td class="event-date">'+v.date.weekday+', '+v.date.month+' '+v.date.day+'</td><td class="event-time">'+v.time+'</td><td><div class="actions"><i class="far fa-edit edit-event" data-open="edit-event-reveal"></i> <i class="fas fa-trash delete-event"></i></div></td></tr>'
                $('.added-events').append(row);
            })
        })
    })

    $(document).on('click','.edit-event',function(){
        var $event = $(this).parent().parent().parent();
        var eventID = $event.attr('id');
        var location = $event.find('.event-location').text();
        var venue = $event.find('.event-venue').text();
        var address = $event.find('.event-address').text();
        var date = $event.find('.event-date').text();
        var time = $event.find('.event-time').text();

        $event.addClass('event-selected');

        var $reveal = $('#edit-event-reveal');
        $reveal.find('[name="event-location"]').val(location);
        $reveal.find('[name="event-venue"]').val(venue);
        $reveal.find('[name="event-address"]').val(address);
        $reveal.find('[name="event-date"]').val(date);
        $reveal.find('[name="event-time"]').val(time);
        $('.update-event').attr('data-event-id', eventID);
    })

    $('.update-event').click(function(){
        var $form = $(this).parent().parent();
        var eventLocation = $form.find('[name="event-location"]').val();
        var eventVenue = $form.find('[name="event-venue"]').val();
        var eventAddress = $form.find('[name="event-address"]').val();
        var eventDate = $form.find('[name="event-date"]').val();
        var eventTime = $form.find('[name="event-time"]').val();
        var id = $(this).data('region-id');
        var eventID = $(this).data('event-id');

        //extract date
        var eventArr = eventDate.replace(',','').split(' ');

        $.ajax({
            url: '/update-event?id='+id+'&event='+eventID,
            type: 'POST',
            data: {
                location: eventLocation,
                venue: eventVenue,
                address: eventAddress,
                date: {
                    month: eventArr[1],
                    weekday: eventArr[0],
                    day: eventArr[2],
                    year: eventArr[3]
                },
                time: eventTime
            }
        }).done(function(response){
            console.log(response);
            if(response.error) return;
            $('.added-events').empty();
            $.each(response,function(i,v){
                var row =  '<tr id="'+v._id+'"><td class="event-location">'+v.location+'</td><td class="event-venue">'+v.venue+'</td><td class="event-address">'+v.address+'</td><td class="event-date">'+v.date.weekday+', '+v.date.month+' '+v.date.day+'</td><td class="event-time">'+v.time+'</td><td><div class="actions"><i class="far fa-edit edit-event" data-open="edit-event-reveal"></i> <i class="fas fa-trash delete-event"></i></div></td></tr>'
                $('.added-events').append(row);
            })

            $('#edit-event-reveal').foundation('close');
            Swal.fire(
                'Success',
                'Seminar Updated',
                'success'
            )
        })

    })

    $(document).on('click','.delete-event',function(){
        var $event = $(this).parent().parent().parent();
        var eventID = $event.attr('id');
        var regionID = $('.added-events').data('region-id');

        $('.event-selected').removeClass('event-selected');
        $event.addClass('event-selected');

        Swal.fire({
            title: 'Are you sure?',
            //text: "You won't be able t!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete'
        }).then(function (result) {
            
            if(result.value) {
                $.ajax({
                    url: '/delete-event?region='+regionID+'&event='+eventID,
                    type: 'POST',
                    data: {},
                }).done(function(response){

                    if(response.error) return;
                    $('.added-events').empty();
                    $.each(response,function(i,v){
                        var row =  '<tr id="'+v._id+'"><td class="event-location">'+v.location+'</td><td class="event-venue">'+v.venue+'</td><td class="event-address">'+v.address+'</td><td class="event-date">'+v.date.weekday+', '+v.date.month+' '+v.date.day+'</td><td class="event-time">'+v.time+'</td><td><div class="actions"><i class="far fa-edit edit-event" data-open="edit-event-reveal"></i> <i class="fas fa-trash delete-event"></i></div></td></tr>'
                        $('.added-events').append(row);
                    })
                    // Swal.fire(
                    //     'Deleted!',
                    //     'Seminar Deleted',
                    //     'success'
                    // )
                })
            } else {
                $('.event-selected').removeClass('event-selected');
            }
        })
    })

    $('.unpublish-button').click(function(e){
        //e.preventDefault()
        var slug = $(this).data('slug');
        var id = $(this).data('id');
        Swal.fire({
            title: 'Are you sure?',
            text: "This will unpublish this region's templates from the live static server.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Unpublish'
        }).then(function (result) {
            
            if(result.value) {
                $.ajax({
                    url: '/unpublish',
                    type: 'POST',
                    data: {
                        slug: slug,
                        id: id
                    }
                }).done(function(res){
                    if (res.error) {
            
                        return Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: res.error
                        })
                    } 
                    Swal.fire(
                        'Success!',
                        'Region Unpublished!',
                        'success'
                    ).then(function(data) {
                        window.location.reload(true)
                    })
                })
            }
        });
        
    })

    $('.archive-button').click(function(e){
        //e.preventDefault()
        var slug = $(this).data('slug');
        var id = $(this).data('id');

        Swal.fire({
            title: 'Are you sure?',
            text: "This will archive the region AND unpublish its templates from the live static server.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Archive'
        }).then(function (result) {
            
            if(result.value) {
                $.ajax({
                    url: '/archive',
                    type: 'POST',
                    data: {
                        slug: slug,
                        id: id
                    }
                }).done(function(res){
                    if (res.error) {
            
                        return Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: res.error
                        })
                    } 
                    Swal.fire(
                        'Success!',
                        'Region Unpublished!',
                        'success'
                    ).then(function(data) {
                        window.location.reload(true)
                    })
                })
            }
        });
    })

    $('.unarchive-button').click(function(e){
        //e.preventDefault()
        var slug = $(this).data('slug');
        var id = $(this).data('id');

        Swal.fire({
            title: 'Note:',
            text: "This will only unarchive the region. Its templates will remain unpublished from the live static server until you republish them.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue'
        }).then(function (result) {
            
            if(result.value) {
                $.ajax({
                    url: '/unarchive',
                    type: 'POST',
                    data: {
                        slug: slug,
                        id: id
                    }
                }).done(function(res){
                    if (res.error) {
            
                        return Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: res.error
                        })
                    } 
                    Swal.fire(
                        'Success!',
                        'Region unarchived!',
                        'success'
                    ).then(function(data) {
                        window.location.reload(true)
                    })
                })
            }
        });
    })

    $('.regen-templates-button').click(function(e){
        //e.preventDefault()
        var slug = $(this).data('slug');
        var id = $(this).data('id');
        Swal.fire({
            title: 'Are you sure?',
            text: "This will also re-publish/override templates to the static server for markets that are LIVE.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue'
        }).then(function (result) {
            
            if(result.value) {
                $.ajax({
                    url: '/regenerate-templates',
                    type: 'POST',
                    data: {
                        slug: slug,
                        id: id
                    }
                }).done(function(res){
                    if (res.error) {
            
                        return Swal.fire({
                            type: 'error',
                            title: 'Oops...',
                            text: res.error
                        })
                    } 
                    Swal.fire(
                        'Success!',
                        'Region Unpublished!',
                        'success'
                    ).then(function(data) {
                        window.location.reload(true)
                    })
                })
            }
        });
        
    })

    //export seminars
    // $('.export-seminars').click(function(){
    //     var regionID = $(this).data('region-id');
    //     $.ajax({
    //         url: '/export-events',
    //         type: 'POST',
    //         data: {
    //             id: regionID,
    //         }
    //     }).done(function(response){
    //         console.log(response);
    //     })
    // })

    $(document).on('closed.zf.reveal', function(){
        $('.event-selected').removeClass('event-selected');
    })


    $('.new-snippet-form').submit(function(e){
        e.preventDefault();
        var $form = $(this)
        var name = $form.find('[name="name"]').val();
        var type = $form.find('[name="type"]').val();
        var location = $form.find('[name="location"]').val();
        var pages = [];

        $form.find('input[type="checkbox"]:checked').each(function(){
            pages.push($(this).val());
        })

        var data = {
            name: name,
            type: type,
            location: location,
            pages: pages
        }
        console.log(data)

        $.ajax({
            url: '/add-snippet',
            type: 'POST',
            data: data
        }).done(function(response){
            if(response.error) return;
            console.log(response);
            window.location.href = '/code-snippet?id='+response.snippet;
        })
    })

    $('#update-snippet-form').submit(function(e){
        e.preventDefault();
        var $form = $(this)
        var id = $form.data('id');
        var name = $form.find('[name="name"]').val();
        var type = $form.find('[name="type"]').val();
        var location = $form.find('[name="location"]').val();
        var code = $form.find('[name="code"]').val();
        var previewFire = false;
        var pages = [];

        $form.find('.page-checkbox:checked').each(function(){
            pages.push($(this).val());
        })

        if($form.find('.preview-checkbox').is(':checked')){
            previewFire = true;
        }

        var data = {
            name: name,
            type: type,
            location: location,
            pages: pages,
            code: code,
            preview_fire: previewFire
        }
        console.log(data)

        $.ajax({
            url: '/update-snippet?id='+id,
            type: 'POST',
            data: data
        }).done(function(response){
            if(response.error) return;
            console.log(response);
            window.location.href = '/code-snippet?id='+response.snippet;
        })
    })

    $('.delete-snippet-trigger').click(function(){
        var id = $(this).data('id');
        Swal.fire({
            title: 'Are you sure?',
            text: "This will delete the snippet. It's recommended to make a backup of your snippet code before deleting.",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue'
        }).then(function (result) {
            $.ajax({
                url: '/delete-snippet?id='+id,
                type: 'POST',
                data: {}
            }).done(function(response){
                if(response.error) return;
                console.log(response);
                Swal.fire({
                    type: 'success',
                    title: 'Tracking Pixels Saved',
                    text: 'Changes will not be reflected on templates until they are regenerated and republished.'
                }).then(function(data) {
                    window.location.href = '/code-snippets';

                })
            })
        })
    })

    $('#pixels-form').submit(function(e){
        e.preventDefault();
        var $form = $(this)
        var id = $form.data('id');
        var gtmScript = $form.find('[name="gtm_script"]').val();
        var gtmNoScript = $form.find('[name="gtm_noscript"]').val();

        var data = {
            gtm_script: gtmScript,
            gtm_noscript: gtmNoScript,
        }
        //console.log(data)

        $.ajax({
            url: '/tracking-pixels?id='+id,
            type: 'POST',
            data: data
        }).done(function(response){
            console.log(response);
            if(response.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error Saving',
                    text: 'Please try again'
                })
                return;
            } 
            Swal.fire({
                type: 'success',
                title: 'Tracking Pixels Saved',
                text: 'Changes will not be reflected on templates until they are regenerated and republished.'
            }).then(function(data) {
                //window.location.reload(true)
            })
            
        })
    })

    $('#settings-form').submit(function(e){
        e.preventDefault();
        var $form = $(this)
        var id = $form.data('id');
        var logo = false;
        if($form.find('[name="admin_logo"]').is(':checked')){
            logo = true;
        }
        var data = {
            admin_logo: logo,
            admin_title: $form.find('[name="admin_title"]').val(),
            event_label: $form.find('[name="event_label"]').val(),
            region_label: $form.find('[name="region_label"]').val(),
            client_name: $form.find('[name="client_name"]').val()
        }
        console.log(data);
        $.ajax({
            url: '/settings?id='+id,
            type: 'POST',
            data: data
        }).done(function(response){
            console.log(response);
            if(response.error){
                Swal.fire({
                    type: 'error',
                    title: 'Error Saving',
                    text: 'Please try again'
                })
                return;
            } 
            window.location.reload(true)    
        })
    })


})